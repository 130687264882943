var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c(
        "div",
        { staticClass: "tabs-inner" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "企业信息", name: "companyInfo" } },
                [
                  _c("ics-customer-inner", {
                    attrs: {
                      "company-detail": _vm.companyDetail,
                      "loading-detail": _vm.loading
                    }
                  })
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "企业资料", name: "companyData" } },
                [
                  _c("ics-company-data-inner", {
                    attrs: {
                      "company-data": _vm.companyData,
                      "loading-detail": _vm.loading
                    }
                  })
                ],
                1
              ),
              _vm.$route.query.state !== "1"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "尽调报告", name: "investigation" } },
                    [
                      _c("ics-investigation-inner", {
                        attrs: {
                          "investigation-info": _vm.investigationInfo,
                          "company-detail": _vm.companyDetail
                        },
                        on: { nextStep: _vm.nextStep }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$route.query.state !== "1"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "提交附件", name: "files" } },
                    [
                      _c("ics-attachment-inner", {
                        ref: "attachmentList",
                        attrs: { "firm-id": _vm.firmId }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$route.query.state !== "1"
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "审核意见", name: "audit" } },
                    [
                      _c("workflow-audit", {
                        attrs: {
                          "business-key": _vm.companyDetail.companyCode,
                          title: "操作记录",
                          "task-id": _vm.$route.query.taskId,
                          "proc-inst-id": _vm.$route.query.procInstId,
                          "view-mode": _vm.auditView()
                        },
                        on: { submit: _vm.submitForms }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }