<template>
  <el-main>
    <page-title show-back-btn />
    <div class="tabs-inner">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="企业信息" name="companyInfo">
          <ics-customer-inner :company-detail="companyDetail" :loading-detail="loading" />
        </el-tab-pane>
        <el-tab-pane label="企业资料" name="companyData">
          <ics-company-data-inner :company-data="companyData" :loading-detail="loading" />
        </el-tab-pane>
        <el-tab-pane v-if="$route.query.state !== '1'" label="尽调报告" name="investigation">
          <ics-investigation-inner :investigation-info="investigationInfo" :company-detail="companyDetail" @nextStep="nextStep" />
        </el-tab-pane>
        <el-tab-pane v-if="$route.query.state !== '1'" label="提交附件" name="files">
          <ics-attachment-inner ref="attachmentList" :firm-id="firmId" />
        </el-tab-pane>
        <el-tab-pane v-if="$route.query.state !== '1'" label="审核意见" name="audit">
          <workflow-audit :business-key="companyDetail.companyCode" title="操作记录" :task-id="$route.query.taskId" :proc-inst-id="$route.query.procInstId" :view-mode="auditView()" @submit="submitForms" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCustomerInner from './components/customer-inner'
import IcsCompanyDataInner from './components/company-data-inner'
import IcsAttachmentInner from './components/attachment-inner'
import IcsInvestigationInner from './components/investigation-inner'
import WorkflowAudit from '@/components/template/workflow-audit'
export default {
  components: { IcsCustomerInner, IcsCompanyDataInner, IcsAttachmentInner, IcsInvestigationInner, WorkflowAudit },
  mixins: [routeEnterMixin],
  data () {
    return {
      firmId: this.$route.query.firmId,
      firmType: this.$route.query.firmType,
      taskId: this.$route.query.taskId,
      hasBack: this.$route.query.hasBack,
      hasRefusal: this.$route.query.hasRefusal,
      activeName: 'companyInfo',
      companyDetail: {},
      investigationInfo: {},
      operatingRecordList: {},
      companyData: {
        tableList: []
      }
    }
  },
  created () {
    if (this.firmId) {
      this.getDetail()
      this.getCompanyDataList()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.admittance.getInfoFirmDetails(this.firmId).then(result => {
        const data  = result.data.data || {}
        let regAddr = []
        let workAddr = []
        if (data.regAddr) {
          regAddr = data.regAddr.split(',')
          data.usEnterpriseAddress = [regAddr[0], regAddr[1], regAddr[2], data.regAddrDetail]
        }
        if (data.workAddr) {
          workAddr = data.workAddr.split(',')
          data.dsEnterpriseAddress = [workAddr[0], workAddr[1], workAddr[2], data.workAddrDetail]
        }
        if (data.firmAccountInfo) {
          if (data.firmAccountInfo.idCardUrlZ) {
            data.idCardUrlZ = data.firmAccountInfo.idCardUrlZ
          }
          if (data.firmAccountInfo.idCardUrlF) {
            data.idCardUrlF = data.firmAccountInfo.idCardUrlF
          }
        }
        this.companyDetail = data
      }).finally(() => {
        this.loading.detail = false
      })
    },
    auditView () {
      if (this.$route.params.editMode === 'audit') {
        return true
      } else {
        return false
      }
    },
    getFirmInpec () {
      this.api.admittance.getFirmInspec(this.firmId).then(result => {
        const data = result.data.data
        this.investigationInfo = data || {}
      })
    },
    handleClick () {
      if (this.activeName === 'investigation') {
        this.getFirmInpec()
      } else if (this.activeName === 'audit') {
        this.getOperatingList()
      }
    },
    getCompanyDataList () {
      this.loading.list = true
      this.api.admittance.firmFileList(this.firmId, this.firmType).then(result => {
        this.companyData.tableList = result.data.data || []
      }).finally(() => {
        this.loading.list = false
      })
    },
    getOperatingList () {
      this.api.operating.listPage({ businessId: this.firmId, pageSize: 99999 }).then(result => {
        this.operatingRecordList = result.data.data
      }).catch(e => {
      })
    },
    nextStep (formData) {
      console.log(formData)
      return new Promise((resolve, reject) => {
        this.loading.submit = true
        const data = formData || {}
        const investigation = {
          firmId: this.companyDetail.firmAccountInfo.firmId,
          firmName: this.companyDetail.firmAccountInfo.firmName,
          trustworthy: data.trustworthy,
          abnormal: data.abnormal,
          implementer: data.implementer
        }
        if (this.investigationInfo || this.investigationInfo.id) {
          investigation.id = this.investigationInfo.id
        }
        if (data.reportUrlFiles.length > 0) {
          investigation.reportUrl = data.reportUrlFiles[0].url
        } else {
          investigation.reportUrl = data.reportUrl
        }
        this.api.admittance.saveFirmInspect(investigation).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('保存成功')
            this.activeName = 'files'
            this.handleClick()
          } else {
            this.loading.submit = false
            this.$message.error('保存失败')
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loading.submit = false
        })
      })
    },
    submitForms (formData) {
      return new Promise((resolve, reject) => {
        this.loading.submit = true
        let data = formData || {}
        data.opinion = data.taskData.remarks
        this.api.admittance.complete(this.taskId, data).then(result => {
          if (result.data.success === true) {
            this.loading = false
            this.$message.success('操作成功')
            this.$router.back()
          } else {
            this.loading = false
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
